import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './Home/App';
import reportWebVitals from './reportWebVitals';
// @ts-ignore
import {BrowserRouter} from "react-router-dom";
import ErrorBoundary from "./UtilityComponents/ErrorBoundary";

ReactDOM.render(
      <BrowserRouter>
          <ErrorBoundary>
              <App />
          </ErrorBoundary>
      </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
